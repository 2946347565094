import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect:"/index"
  },
  {
    path: '/', //布局
    meta:{title:'首页'},
    component: () => import('@/views/layout/Main'),
    children:[
      {
        path: '/index', //首页
        name: 'index',
        meta:{title:'首页'},
        component: () => import('@/views/pages/index/Index')
      }
    ]
  }

]

const router = new VueRouter({
  routes
})

export default router
